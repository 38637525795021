<template>
  <section>
    <div class="logo">
      <router-link to="/">
        <img
          src="@/assets/images/logo-lowco.png"
          alt="Lowco logo"
        >
      </router-link>
    </div>
    <form @submit.prevent="onSubmit">
      <div class="control">
        <input
          name="username"
          type="text"
          @change="onChange"
        >
        <label for="username">Email</label>
      </div>
      <div class="control">
        <input
          name="password"
          type="password"
          @change="onChange"
        >
        <label for="password">Mot de passe</label>
      </div>

      <Button class="mt-2" type="submit" block>
        Se connecter
      </Button>

      <router-link
        to="/password-lost"
        class="lost_password"
      >
        Mot de passe oublié ?
      </router-link>

      <div class="sign-up-link">
        <span>
          {{ t('pages.login.signUpDescription') }}
        </span>
        <LinkButton extra-small :to="routesConstants.SIGN_UP" :buttonClass="$style.signUpButton">
          {{ t('pages.login.signUpLink') }}
        </LinkButton>
      </div>

      <div
        v-if="isSubmitted && error"
        class="lowco-textbox lowco-textbox-error"
        v-html="getErrorLabel()"
      />
      <div
        v-else-if="isSubmitted"
        class="lowco-textbox lowco-textbox-success"
      >
        Connexion réussie
      </div>

    </form>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';

import useAuthentication from '@/composables/useAuthentication';
import loginConstants from '@/constants/login.constants';
import Button from '@/components/common/Button.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import routesConstants from '@/constants/routes.constants';
import useCurrentCompany from '@/composables/useCurrentCompany';

export default {
  name: 'Login',
  components: {
    Button,
    LinkButton,
  },
  setup() {
    const { t } = useI18n();
    const { signIn } = useAuthentication();
    const { clearCompany } = useCurrentCompany();

    return {
      t,
      signIn,
      clearCompany,
      routesConstants,
    };
  },
  data() {
    return {
      formData: null,
      isSubmitted: false,
      error: null,
    };
  },
  methods: {
    onChange(event) {
      const { name, value } = event.target;
      this.formData = {
        ...this.formData,
        [name]: value,
      };
    },
    async onSubmit() {
      const hasEmptyValue = Object.keys(this.formData).some((key) => !this.formData[key]);

      if (hasEmptyValue) {
        this.error = loginConstants.EMPTY_FIELD;
        return;
      }

      try {
        this.clearCompany();
        await this.signIn(this.formData);
        this.error = null;
      } catch (err) {
        this.error = loginConstants.LOGIN_ERROR;
      } finally {
        this.isSubmitted = true;
      }
    },
    getErrorLabel() {
      switch (this.error) {
        case loginConstants.EMPTY_FIELD:
          return 'Veuillez remplir tous les champs';
        case loginConstants.LOGIN_ERROR:
          return 'Une erreur est survenue à la connexion';
        default:
          return 'Une erreur générale est survenue, veuillez réssayer plus tard';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";
@import "../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogo();
@include centeredLayoutWithLogoForm();

.lost_password {
  display: block;
  margin: 1.5rem auto 0;
  font-size: 1.3rem;
  text-align: center;
  width: fit-content;
}

.sign-up-link {
  margin-top: 2rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style lang="scss" module>
.signUpButton {
  min-height: auto;
  background-color: $dark-white;
  color: $dark-green;
}
</style>

